
import Vue from "vue";
import Cookies from "js-cookie";

export default Vue.extend<any, any, any, any>({
  name: "ThankYou",
  data() {
    return {
      petName: this.$route.query.petName
    };
  },
  mounted() {
    Cookies.remove("preRegistrationToken");
  }
});
